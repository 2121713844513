<template>
  <div>

    <b-card>
      <h2>Profile - <span v-if="local.firstname && local.lastname">{{local.firstname}} {{local.lastname}}</span><span v-else>{{local.username}}</span></h2>
    </b-card>

    <div class="row">
      <div class="col-md-4 col-xl-3">
        <b-card>
          <div slot="header">
            <h5 style="margin-bottom: 0"><b>Profile Info</b><fa-icon :icon="['fas', 'pencil']" size="sm" @click="showEditProfile" class="ml-2"/></h5>
          </div>
          <span style="font-size:15px"><b>Name:</b> {{local.firstname}} {{local.lastname}} <br>
          <b>Username:</b> {{local.username}} <br>
          <b>Email:</b> {{local.email}} <br>
          <b>User Type:</b> {{local.type}}</span>
        </b-card>
      </div>

      <div class="col-md-4 col-xl-3">
        <b-card>
          <div slot="header">
            <h5 style="margin-bottom: 0"><b>Time</b></h5>
          </div>
          <b-form-checkbox v-model="militaryTime" switch>
            Military Time
          </b-form-checkbox>
        </b-card>
      </div>
    </div>


    <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""></b-tooltip>
  </div>
</template>

<script>
  import Vue from 'vue'
  import fcr from '../../../services/fcr.js'
  import iss from '../../../services/iss.js'
  import axios from 'axios'
  import {ClientTable} from 'vue-tables-2'
  Vue.use(ClientTable)

  export default {
    data: () => {
      return {
        militaryTime: false,
        editProfileModal: false,
        local: {},
        tooltipTitle:"",
        tooltipRender:true,
        target: '',
      }

    },

    beforeRouteLeave (to, from, next) {
        localStorage.setItem('militaryTime', String(this.militaryTime));
        next();
    },

    mounted(){
      this.local=localStorage;
      this.militaryTime = localStorage.militaryTime === 'true'
    },

    methods: {
      showEditProfile(){

      }
    },
  }
</script>
